import { rand } from "../../../../utility/random";

export interface ColorMap<T> {
  r: T;
  g: T;
  b: T;
}

export interface Color extends ColorMap<number> { }

export interface ColorAdjust extends Partial<Color> { }

export type ColorId = keyof Color;

export type ColorMapFn<T> = (value: number, c: ColorId) => T;

export const mapColors = <T extends any>(color: Color, map: ColorMapFn<T>): ColorMap<T> => {
  return {
    r: map(color.r, 'r'),
    g: map(color.g, 'g'),
    b: map(color.b, 'b'),
  };
};

export const mapColorValues = <T extends any>(color: Color, map: ColorMapFn<T>) => {
  return Object.values(mapColors(color, map));
};

export const colorToHex = (color: Color): string => {
  const mapped = mapColors(color, v => ('0' + v.toString(16)).substr(-2));
  return `#${mapped.r}${mapped.g}${mapped.b}`;
};

export const hexToColor = (hex: string): Color => {
  hex = hex.replace('#', '');

  const [r, g, b] = [
    hex.substr(0, 2),
    hex.substr(2, 2),
    hex.substr(4, 2)
  ]
    .map(v => parseInt(v, 16));
  return {
    r,
    g,
    b,
  };
};

export const ifColor = (value: number, original: number): number => {
  if (0 <= value && 0xFF >= value) {
    return value;
  }
  return original;
};

export const mapColorsSafe = (color: Color, map: ColorMapFn<number>): Color => {
  return mapColors(color, (v, i) => {
    return ifColor(map(v, i), v);
  });
};

export const chooseColor = (): Color => {
  const choice = mapColorsSafe({
    r: rand(0, 255),
    g: rand(0, 255),
    b: rand(0, 255),
  }, v => Math.round((v) / 15) * 15);
  return choice;
};

export const adjustColor = (color: Color, adjustment: ColorAdjust): Color => {
  return mapColorsSafe(color, (v, i) => {
    return v + (adjustment[i] ?? 0);
  });
};

export const complement = (color: Color): Color => {
  return mapColorsSafe(color, v => 0xFF - v);
};

export const muted = (color: Color): Color => {
  const average = mapColorValues(color, v => v).reduce((r, v) => r + v, 0) / 3;
  return mapColorsSafe(color, v => Math.round((average + v) / 2));
};

export const vibrant = (color: Color): Color => {
  const mutedColor = muted(color);
  return mapColorsSafe(color, (v, i) => {
    return v + (v - mutedColor[i]);
  });
};

export const brighter = (color: Color): Color => {
  return mapColorsSafe(color, v => Math.round((v + 0xFF) / 2));
};

export const darker = (color: Color): Color => {
  return mapColorsSafe(color, v => Math.round(v / 2));
};