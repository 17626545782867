import React from 'react';
import styles from './Column.module.css';
import { styleAs, Styleable } from '../utility/style';

export interface ColumnProps extends Styleable {
}

export const Column: React.FC<ColumnProps> = (props) => {
  const { children } = props;
  return (
    <div className={styleAs(
      styles.column,
      props
    )}>
      {children}
    </div>
  );
};