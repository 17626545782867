import React, { useState } from 'react';
import { useLocation, useQueryParams } from '../routing/Router';
import { Button } from './basic/Button';
import { genId } from '../routing/routing-util';
import { Input } from './basic/Input';
import { useStateExt } from '../hooks/useStateExt';
import { WarnAlert } from './alert/Alert';

const protectValue = (key: string, value: string) => {
  let result = value.split('')
    .map((v, i) => {
      return String.fromCharCode(v.charCodeAt(0) + key.charCodeAt(i % key.length));
    })
    .join('');
  result = btoa(result);
  return result;
};

const revealValue = (key: string, value: string) => {
  value = atob(value);
  return value.split('')
    .map((v, i) => {
      return String.fromCharCode(v.charCodeAt(0) - key.charCodeAt(i % key.length));
    })
    .join('');
};

interface SimpleGuardBuilderProps {
  guardKey?: string;
  guardValue?: string;
}

const SimpleGuardBuilder: React.FC<SimpleGuardBuilderProps> = (props) => {
  const { guardKey, guardValue } = props;
  const showKey = guardKey ?? genId();
  const keyLabel = props.guardKey ? 'Key' : 'Generated Key';

  const [value, , valuePair] = useStateExt(guardValue ? revealValue(showKey, guardValue) : '');
  const [, setGuardValue, guardPair] = useStateExt(guardValue ?? protectValue(showKey, value));

  return (
    <div style={{ border: '1px solid #CCC', padding: '1em' }}>
      SimpleGuard builder
      <Input
        label={keyLabel}
        defaultValue={showKey}
        readOnly
      />
      {!guardKey && <>
        <p>
          Copy+paste the guardKey into the code and save it to continue.
        </p>
      </>}
      {guardKey && <>
        <Input
          label='Value'
          setPair={valuePair}
          onChange={(v: string) => {
            const guarded = protectValue(guardKey, v);
            setGuardValue(guarded);
          }}
        />
        <Input
          label='Guard Value'
          setPair={guardPair}
          readOnly
        />
      </>}
    </div>
  );
};

export interface SimpleGuardProps {
  description: React.ReactNode;
  guardKey?: string;
  guardValue?: string;
}

/**
 * SimpleGuard is meant to protect mostly public information from abuse/harvesting
 * by requiring the user to click a button to reveal the information. It has logic
 * baked in which will check for a url param of:
 *  simpleGuard=create
 * and also check for localhost. If those conditions are met, it will transform
 * into creation mode and allow for data to be set (to later be revealed).
 */
export const SimpleGuard: React.FC<SimpleGuardProps> = (props) => {
  const { description, guardKey, guardValue } = props;

  const location = useLocation();
  const params = useQueryParams();

  const [revealed, setRevealed] = useState(null as string | null);

  if ('localhost' === location.hostname) {
    const [simpleGuard] = params?.simpleGuard ?? [];
    if ('create' === simpleGuard) {
      return (
        <SimpleGuardBuilder guardKey={guardKey} guardValue={guardValue} />
      );
    }
  }

  if (revealed) {
    return <Input defaultValue={revealed} readOnly center selectOnClick />
  }

  if (!guardKey || !guardValue) {
    return (
      <WarnAlert>
        This information is not available, the developer messed up!
      </WarnAlert>
    );
  }

  return (
    <Button
      action={() => {
        setRevealed(revealValue(guardKey ?? '', guardValue ?? ''));
      }}
      wide
    >
      Reveal {description}
    </Button>
  );
};