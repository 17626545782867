import React, { useState } from 'react';
import styles from './Help.module.css';
import { Button } from './basic/Button';
import { SetPair } from '../hooks/useStateExt';
import { Modal } from './Modal';

export enum HelpType {
  modal = 'modal',
}

export enum IconType {
  question = '?',
  help = 'help',
}

export interface HelpProps {
  startOpen?: boolean;
  collapsedPair?: SetPair<boolean>;

  type?: HelpType;
  icon?: IconType;
}

export const Help: React.FC<HelpProps> = (props) => {
  const {
    children,
    startOpen: defOpen,
    collapsedPair,
    type = HelpType.modal,
    icon = IconType.question,
  } = props;

  const [open, setOpen] = (collapsedPair ?? useState(defOpen)) as SetPair<boolean>;

  return (
    <>
      <Button
        className={styles.btn}
        action={() => setOpen(true)}
      >
        {icon}
      </Button>

      {HelpType.modal === type &&
        <Modal onClose={() => setOpen(false)} open={open}>
          {children}
        </Modal>
      }
    </>
  );
};