import React from 'react';
import graphic from './Github-octicon.svg';
import { SvgSymbol } from '../SvgSymbol';
import { ExtMarkLink } from './ExtMark';

const symbol = <SvgSymbol src={graphic} alt='github octicon' />;

export interface GithubMarkProps {
}

export const GithubMark: React.FC<GithubMarkProps> = (props) => {
  return (
    <>
      <ExtMarkLink name='GitHub' symbol={symbol} linkTo='https://www.github.com/codaer' />
    </>
  );
};