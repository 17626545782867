export enum AnimateSpeed {
  fast = 'fast',
}

export const filterNonBlankUnique = (v: any, i: number, arr: any[]) => {
  return v && i === arr.indexOf(v);
};

/**
 * Generates a series of intervals of the given length of time in milliseconds.
 * @param count Number of intervals
 * @param ms Timing of the intervals
 */
export const genTiming = (count: number, ms: number) => {
  return new Array(count).fill(ms);
};

export const CHOICE_TIMING = {
  [AnimateSpeed.fast]: [
    ...genTiming(24, 50),
  ],
};