import React from 'react';
import styles from './Header.module.css';

export interface HeaderProps {
}

export const Header: React.FC<HeaderProps> = (props) => {
  const { children } = props;
  return (
    <p className={styles.header}>
      {children}
    </p>
  );
};