import React from 'react';
import styles from './Row.module.css';
import { Styleable, styleAs, styleIf } from '../utility/style';
export { styles as rowStyles };

export interface RowProps extends Styleable {
  spaceEven?: boolean;
}

export const Row: React.FC<RowProps> = (props) => {
  const { children, spaceEven } = props;

  return (
    <div className={styleAs(
      styles.row,
      styleIf(spaceEven, styles.spaceEven),
      props
    )}>
      {children}
    </div>
  );
};