import React from 'react';
import styles from './Section.module.css';
import { styleIf, styleAs } from '../utility/style';
import { SectionLabel } from './basic/SectionLabel';

export interface SectionProps {
  border?: boolean;
  label?: React.ReactNode;
}

export const Section: React.FC<SectionProps> = (props) => {
  const { children, border, label } = props;
  return (
    <div className={styleAs(
      styles.section,
      styleIf(border, styles.border)
    )}>
      {label && <SectionLabel>{label}</SectionLabel>}
      {children}
    </div>
  );
};