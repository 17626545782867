export interface Styleable {
  className?: string;
}
export type StyleArg = string | undefined;
export type StyleArgs = StyleArg[];
export type StyleTypes = Styleable | StyleArg | StyleArgs;

const isStyleable = (value: StyleTypes): value is Styleable => {
  return 'string' !== typeof value && !Array.isArray(value);
};

const toStyleArgs = (value: StyleTypes): StyleArgs => {
  let result = value;
  if (undefined === value) {
    return [];
  }

  if (isStyleable(value)) {
    result = value?.className?.split(' ') || [];
  } else if ('string' === typeof value) {
    result = [value];
  }
  return result as StyleArgs;
};

export const styleAs = (className: string, ...inherit: (StyleTypes)[]) => {
  const styleArgs = inherit
    .map(v => toStyleArgs(v))
    .reduce((result, value) => {
      result.push(...value);
      return result;
    }, [] as StyleArgs);

  return [
    ...className.split(' '),
    ...styleArgs,
  ]
    .filter((v, i, arr) => {
      // Only truthy values and unique values
      return v && i === arr.indexOf(v);
    })
    .join(' ');
};

type RawCondition = boolean | undefined;
type Condition = RawCondition | (() => RawCondition);
export const styleIf = (condition: Condition, style: StyleArg) => {
  if ('function' === typeof condition) {
    condition = condition();
  }
  if (condition) {
    return style;
  }
  return [];
};