import React from 'react';
import styles from './Center.module.css';
import { Styleable, styleAs } from '../utility/style';

export interface CenterProps extends Styleable {
}

export const Center: React.FC<CenterProps> = (props) => {
  const { children } = props;
  return (
    <span className={styleAs(styles.center, props)}>{children}</span>
  );
};