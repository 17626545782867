import React, { useState, useLayoutEffect, useRef } from 'react';
import styles from './Modal.module.css';
import { Button } from './basic/Button';

/**
 * Hook the provides the width of the scrollbars being used.
 */
const useScrollWidth = () => {
  const [width] = useState(() => {
    if (document) {
      const tempDiv = document.createElement('div');
      tempDiv.style.height = '50px';
      tempDiv.style.overflow = 'scroll';
      tempDiv.style.width = '50px';
      document.body.appendChild(tempDiv);
      const result = tempDiv.offsetWidth - tempDiv.clientWidth;
      document.body.removeChild(tempDiv);
      return result;
    }
  });
  return width;
};

enum ScrollMarkerState {
  inform = 'inform',
  hide = 'hide',
  unknown = 'unknown',
}

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { children, onClose, open } = props;

  const scrollWidth = useScrollWidth();
  const contentRef = useRef(null as HTMLDivElement | null);

  const [scrollMarker, setScrollMarker] = useState(ScrollMarkerState.unknown);

  useLayoutEffect(() => {
    if (contentRef.current && ScrollMarkerState.hide !== scrollMarker) {
      const { current } = contentRef;
      if (current.scrollHeight > current.clientHeight) {
        setScrollMarker(ScrollMarkerState.inform);
      }
    }
  }, [open, scrollMarker]);

  useLayoutEffect(() => {
    const wrapper = document?.body;
    if (wrapper && scrollWidth) {
      const adjustSize = () => {
        // If we don't clear the previous value, we will get a bad clientWidth
        wrapper.style.width = '';

        wrapper.classList.add(styles.bodyScrollBlock);
        wrapper.style.width = (document.body.clientWidth - scrollWidth) + 'px';
      };
      const clear = () => {
        wrapper.classList.remove(styles.bodyScrollBlock);
        wrapper.style.width = '';
      };
      const cleanup = () => {
        clear();
        window.removeEventListener('resize', adjustSize);
      };

      if (open) {
        adjustSize();
        window.addEventListener('resize', adjustSize);
      } else {
        cleanup();
      }
      return cleanup;
    }
  }, [open, scrollWidth]);

  return (
    <>
      {open &&
        <div className={styles.gutter} onClick={onClose}>
          <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
            <Button className={styles.topCloseButton} action={onClose}>x</Button>

            {ScrollMarkerState.inform === scrollMarker && <>
              <span className={styles.scrollMarker}>
                Scroll for more content
              </span>
            </>}

            <div ref={contentRef} className={styles.content} onScroll={() => {
              if (ScrollMarkerState.inform === scrollMarker)
                setScrollMarker(ScrollMarkerState.hide);
            }}>
              {children}
            </div>

            <Button className={styles.bottomCloseButton} action={onClose}>Done</Button>
          </div>
        </div>
      }
    </>
  );
};