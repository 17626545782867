import React from 'react';
import { Grid } from '../../../../ui/Grid';
import { Button } from '../../../../ui/basic/Button';
import { Color, adjustColor, ColorId } from './shared';
import { SetPair } from '../../../../hooks/useStateExt';

export interface AdjustButtonsProps {
  style: string;
  colorId: ColorId;
  colorPair: SetPair<Color>;
  adjustSize: number;
}

export const AdjustButtons: React.FC<AdjustButtonsProps> = (props) => {
  const { style, colorId, colorPair, adjustSize } = props;
  const [color, setColor] = colorPair;
  return (
    <Grid noGap values={[
      <Button
        action={() => {
          setColor(adjustColor(color, {
            [colorId]: adjustSize
          }))
        }}>+{adjustSize}</Button>,
      <span className={style}>{colorId.toUpperCase()}: {color[colorId]}</span>,
      <Button
        action={() => {
          setColor(adjustColor(color, {
            [colorId]: -adjustSize
          }))
        }}>-{adjustSize}</Button>
    ]} />
  );
};