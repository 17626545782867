import React from 'react';
import { Panel } from '../../layout/Panel';
import { asView } from '../../routing/View';
import { BackwardsCompat } from './BackwardsCompat';

export interface DecisionMakerProps {
}

export const DecisionMaker: React.FC<DecisionMakerProps> = (props) => {
  const { children } = props;
  return (
    <Panel tall={true} wide={true}>
      <BackwardsCompat />
      {children}
    </Panel>
  );
};

export const DecisionMakerView = asView(DecisionMaker, {
  header: 'Decision Maker',
  path: '/decision-maker',
});