import React from 'react';
import { SvgSymbol } from '../../branding/SvgSymbol';
import graphics from './Settings.svg';
import { MarkLink } from '../../ui/basic/MarkLink';

const mark = <SvgSymbol src={graphics} alt='settings icon' />;

export interface SettingsMarkProps {
}

export const SettingsMark: React.FC<SettingsMarkProps> = (props) => {
  return (
    <MarkLink name='Settings' linkTo='/settings'>
      {mark}
    </MarkLink>
  );
};