import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import styles from './ChooseFrom.module.css';
import { Button, ButtonProps } from '../../../../ui/basic/Button';
import { TextInput } from '../../../../ui/TextInput';
import { ActionBar } from '../../../../ui/ActionBar';
import { styleAs } from '../../../../utility/style';
import { Help } from '../../../../ui/Help';
import { asView } from '../../../../routing/View';
import { setData, getData, useStateExtPersisted } from '../../../../utility/store';
import { filterNonBlankUnique } from '../shared';
import { useStateExt } from '../../../../hooks/useStateExt';
import { ChooserState, Chooser } from '../chooser/Chooser';
import { rand } from '../../../../utility/random';
import { AnimateConfigButton } from '../chooser/PersistConfigButton';
import { Modal } from '../../../../ui/Modal';
import { HelpSection } from '../../../../ui/HelpSection';
import { useManifest } from '../../../../hooks/useManifest';

export interface ChooseFromProps {
  collapseHelp?: boolean;
}

export const ChooseFrom: React.FC<ChooseFromProps> = (props) => {
  const [candidates, setCandidates] = useStateExtPersisted('candidates', [] as string[]);
  const [chooseOnLoad, setChooseOnLoad] = useStateExtPersisted('chooseOnLoad', true);

  const [editing, setEditing] = useState(() => getData()?.candidates?.length ? false : true);
  const [bulkEdit, setBulkEdit] = useState(false);
  const [selected, setSelected, selectedPair] = useStateExt(null as string | null);

  const [chooserState, setChooserState, chooserStatePair] = useStateExt(ChooserState.done);

  const [animate, , animatePair] = useStateExtPersisted('animate', true);

  // Updates for preferences
  useEffect(() => {
    setData('candidates', candidates);
  }, [candidates]);
  useEffect(() => {
    setData('chooseOnLoad', chooseOnLoad);
  }, [chooseOnLoad]);

  // To run on first load
  useLayoutEffect(() => {
    if (getData()?.chooseOnLoad && !editing) {
      setChooserState(ChooserState.chooseValue);
    }
    // This is purposefully done only on initital load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // All of the candidates
  const candidatesShow = useMemo(() => {
    return candidates
      .map(v => {
        const extras = [];
        if (editing) {
          if (selected === v) {
            extras.push(styles.editSelect);
          }
        } else {
          if (animate) {
            if (!selected) {
              extras.push(styles.moveToReset);
            }
            if (selected === v) {
              extras.push(styles.moveToChosen);
            } else {
              extras.push(styles.moveToLoser);
            }
          } else {
            if (selected === v) {
              extras.push(styles.selected);
            } else {
              extras.push(styles.unselected);
            }
          }
        }

        return (
          <Button
            action={() => editing && setSelected(v)}
            className={styleAs(styles.badge, extras)}
            key={v}
            noCap
          >
            {v}
          </Button>
        )
      });
  }, [candidates, selected, setSelected, animate, editing]);

  const addCandidate = (value: string) => {
    setCandidates(old => [...old, value].filter(filterNonBlankUnique));
  };

  const actions = useMemo(() => {
    const removeCandidate = (value: string) => {
      setCandidates(old => [...old.filter(v => value !== v)]);
    };

    const result: any = {};

    if (editing) {
      if (selected) {
        result.delete = {
          label: 'Delete',
          action: () => {
            if (selected) {
              removeCandidate(selected);
            }
          },
        };
        result.unselect = {
          label: 'Unselect',
          action: () => setSelected(null),
        };
      }

      result.clearAll = {
        label: 'Clear all',
        action: () => {
          setCandidates([]);
        },
      };

      result.bulkEdit = {
        label: 'Bulk edit',
        action: () => {
          setBulkEdit(true);
        },
      };

      result.done = {
        label: 'Done editing',
        action: () => {
          setEditing(false);
        },
      };
    } else {
      if (selected && ChooserState.done === chooserState) {
        result.chooseAgain = {
          label: 'Choose again',
          action: () => setChooserState(ChooserState.chooseValue),
        };
      } else {
        result.choose = {
          label: 'Choose',
          action: () => setChooserState(ChooserState.chooseValue),
        };
      }
      result.animate = {
        fn: (props: ButtonProps) => <AnimateConfigButton persistStatePair={animatePair} {...props} />
      };
      result.chooseOnLoad = {
        label: 'Auto choose is ' + (chooseOnLoad ? 'on' : 'off'),
        action: () => setChooseOnLoad((o: boolean) => !o),
      };
      result.edit = {
        label: 'Edit values',
        action: () => setEditing(true),
      };

      if (null !== selected) {
        result.showValues = {
          label: 'Show values',
          action: () => setSelected(null),
        };
      }
    }

    return result;
  }, [selected, setSelected, chooserState, setChooserState, editing, chooseOnLoad, setCandidates, setChooseOnLoad, animatePair]);

  useManifest({
    override: {
      short_name: 'Choose From by Codaer',
    },
    genStartUrl: true,
  });

  return (
    <>
      <Help>
        <HelpSection label='What is this?'>
          This Decider is meant to help choose between multiple options. No duplicates are
          allowed and every option has an equal chance to be chosen. It is useful when deciding
          between things that are already known.
        </HelpSection>

        <HelpSection label='How do I use it?'>
          Either add new values individually or use the bulk edit feature. When using bulk edit,
          each line is used as a value. After you are finished, click the done editing button.
        </HelpSection>

        <HelpSection label='Customization'>
          <ul>
            <li>Animation: cycles through the options before choosing one</li>
            <li>Auto choose: makes it so that the value is automatically chosen when the site reloads</li>
          </ul>
        </HelpSection>

        <HelpSection label='Save for later'>
          Just like all of the other Deciders, this Decider can be saved for quick use
          by saving it as a bookmark. Once it is saved as a bookmark, just click on the
          bookmark, and the Decider will be loaded with your values.
        </HelpSection>
      </Help>

      <div>
        {editing && !bulkEdit && <TextInput
          label='Value'
          buttonLabel='Add Value'
          onButtonClick={({ value, setValue }) => {
            addCandidate(value);
            setValue('');
          }}
        />}

        <ActionBar
          disabled={ChooserState.done !== chooserState}
          onAll={() => selected && setSelected(null)}
          actions={actions}
        />
      </div>

      {editing && bulkEdit && <>
        <Modal open={bulkEdit} onClose={() => setBulkEdit(false)}>
          <textarea
            className={styles.bulkEditText}
            defaultValue={candidates.join('\n')}
            onBlur={({ target: { value } }) => {
              setCandidates(value.split('\n').filter(filterNonBlankUnique))
            }}
          />
          <span className={styles.note}>Note: bulk editing requires one value per line</span>
        </Modal>
      </>}

      {((editing && !bulkEdit) || (!editing && !selected)) && <div className={styles.candidateWrap}>
        {candidatesShow}
      </div>}

      {!editing &&
        <Chooser
          animate={animate}
          chooserStatePair={chooserStatePair}
          selectedPair={selectedPair}
          valueGen={() => {
            return candidates[rand(candidates.length)];
          }}
        />
      }
    </>
  );
};

export const ChooseFromView = asView(ChooseFrom, {
  header: 'Choose From',
});