import React, { useContext, useState } from 'react';
import { LinkProps, LinkClickEventHandler } from './Link';

interface LinkConfigState {
  onClick?: LinkClickEventHandler;
  publicPath?: (v: string) => string;
}

const publicPath = (window as any)?.aePublicPath ?? '';

const LinkConfigContext = React.createContext({} as LinkConfigState);

export const useLinkConfig = () => {
  const config = useContext(LinkConfigContext);
  return config;
}

export interface LinkConfigProps extends Omit<LinkProps, 'to'> {
}

export const LinkConfig: React.FC<LinkConfigProps> = (props) => {
  const { children, onClick } = props;
  const [configState] = useState({
    onClick,
    publicPath: (orig) => {
      return publicPath + orig;
    },
  } as LinkConfigState);
  return (
    <>
      <LinkConfigContext.Provider value={configState}>
        {children}
      </LinkConfigContext.Provider>
    </>
  );
};