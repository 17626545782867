import React from 'react';
import { asView } from '../routing/View';
import { Panel } from '../layout/Panel';
import { GithubMark } from '../branding/external/GithubMark';
import { Link } from '../navigation/Link';
import { MarkRow } from '../ui/basic/MarkRow';

export interface ProfilesProps {
}

export const Profiles: React.FC<ProfilesProps> = (props) => {
  return (
    <Panel>
      <p>
        This page links to all of the relevant profiles that we maintain for Codaer. If
        there's a service not listed here that you think we should be a part of,
        please <Link to='/contact'>let us know</Link>!
        </p>
      <MarkRow>
        <GithubMark />
      </MarkRow>
    </Panel>
  );
};

export const ProfilesView = asView(Profiles, {
  header: 'Profiles',
});