import React from 'react';
import { Section } from './Section';

export interface HelpSectionProps {
  label: string;
}

export const HelpSection: React.FC<HelpSectionProps> = (props) => {
  const { children, label } = props;
  return (
    <Section label={label}>
      {children}
    </Section>
  );
};