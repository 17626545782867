import React, { useState, useRef } from 'react';
import { useId } from '../../hooks/useId';
import styles from './basic.module.css';
import { SetPair } from '../../hooks/useStateExt';
import { styleAs } from '../../utility/style';

export interface InputProps {
  defaultValue?: string;
  onChange?: (v: string) => void;

  setPair?: SetPair<string>;

  label?: React.ReactNode;

  readOnly?: boolean;
  center?: boolean;
  selectOnClick?: boolean;
}

export const Input: React.FC<InputProps> = (props) => {
  const { defaultValue, onChange, label, setPair, readOnly, center, selectOnClick } = props;

  const [value, setValue] = setPair ?? useState(defaultValue);
  const id = useId();
  const inputRef = useRef(null as HTMLInputElement | null);

  const extras = [];
  if (center) {
    extras.push(styles.center);
  }

  return (
    <div className={styles.inputWrap}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        ref={inputRef}

        className={styleAs(styles.input, extras)}
        id={id}
        value={value}
        onClick={() => {
          if (selectOnClick) {
            const { current } = inputRef;
            current?.focus();
            current?.select();
          }
        }}
        readOnly={readOnly}
        onChange={({ target: { value: v } }) => {
          setValue(v);
          onChange?.(v);
        }}
      />
    </div>
  );
};