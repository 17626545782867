import React from 'react';
import { SvgSymbol } from '../../../../branding/SvgSymbol';
import graphic from './ColorChooser.svg';
import { MarkLink } from '../../../../ui/basic/MarkLink';

const mark = <SvgSymbol src={graphic} alt='color chooser mark' />

export interface ColorChooserMarkProps {
}

export const ColorChooserMark: React.FC<ColorChooserMarkProps> = (props) => {
  return (
    <MarkLink name='Color Chooser' linkTo='/decision-maker/color-chooser'>
      {mark}
    </MarkLink>
  );
};