import React from 'react';
import { Header } from './shared';
import { genId } from './routing-util';
import { isView } from './View';

interface Component {
  id: string;
  header: Header;
  priority: number;
  parent: string | null;
  path: string;
}

export interface AnalysisContext {
  componentTree: { [k: string]: string | null };
  components: { [k: string]: Component };
}

export interface AnalysisContextInternal extends AnalysisContext {
  idStack: string[];
  pathStack: string[];
  node: React.ReactElement;
  priority: number;
}

interface AnalysisResult {
  ctx: AnalysisContext;
  remapped: React.ReactNode;
}

const analyzeComponentsInternal = (ctx: AnalysisContextInternal) => {
  const { node } = ctx;
  const { header, path } = node.props;
  const replProps = { ...node.props };

  const viewId = isView(node) ? genId() : null;
  if (viewId) {
    ctx.idStack.push(viewId);
    replProps.viewId = viewId;

    const [parentId, childId] = ctx.idStack.slice(-2);
    if (parentId && childId) {
      ctx.componentTree[childId] = parentId;
    }

    if (path) {
      if (path.endsWith('/*')) {
        ctx.pathStack.push(path.substr(0, path.length - 2));
      } else {
        ctx.pathStack.push(path);
      }
    }

    const fullPath = ctx.pathStack.join('');
    ctx.components[viewId] = {
      id: viewId,
      header,
      priority: ctx.priority,
      parent: ctx.idStack.slice(-1)[0] || null,
      path: fullPath,
    };
    replProps.path = fullPath;
  }

  replProps.priority = ctx.priority;
  ctx.priority = ctx.priority * 2;
  replProps.children = React.Children.map(node.props.children, (child) => {
    ctx.node = child;
    const result = analyzeComponentsInternal(ctx);
    ctx.node = node;
    return result;
  });
  ctx.priority = ctx.priority / 2;

  if (Object.values(ctx.componentTree).includes(viewId)) {
    // This is a parent with child views... it should be a wildcard
    replProps.path += '/*';
  }

  if (viewId) {
    ctx.idStack.pop();
    if (path) {
      ctx.pathStack.pop();
    }
  }

  return React.cloneElement(
    node,
    replProps,
    replProps.children
  );
};

export const analyzeAndRebuild = (props: any): AnalysisResult => {
  const ctx = {
    idStack: [],
    pathStack: [],
    node: { props } as React.ReactElement,
    priority: 1,

    componentTree: {},
    components: {},
  };
  const result = analyzeComponentsInternal(ctx);

  const remapped: React.ReactNode = result.props.children;

  if (0 !== ctx.idStack.length) {
    throw new Error(`Mismatched push/pop from idStack`);
  }
  if (0 !== ctx.pathStack.length) {
    throw new Error(`Mismatched push/pop from pathStack`);
  }

  return {
    remapped,
    ctx,
  };
};
