import React, { useState, useLayoutEffect } from 'react';
import styles from './Chooser.module.css';
import { SetPair, useStateExt } from '../../../../hooks/useStateExt';
import { styleAs, styleIf } from '../../../../utility/style';
import { AnimateSpeed, CHOICE_TIMING } from '../shared';

export enum ChooserState {
  chooseValue = 'chooseValue',
  waiting = 'waiting',
  done = 'done',
}

export const useChooserState = () => {
  return useStateExt(ChooserState.done);
};

export interface ChooserProps<T> {
  valueGen: () => T;
  chooserStatePair: SetPair<ChooserState>;
  selectedPair: SetPair<T | null>;

  animate?: boolean;
}

export const Chooser = <T extends any>(props: ChooserProps<T>) => {
  const { animate, selectedPair, chooserStatePair, valueGen } = props;

  const [selected, setSelected] = selectedPair;
  const [chooserState, setChooserState] = chooserStatePair;

  const [lastSelected, setLastSelected] = useState(new Date());

  const [animateSpeed] = useState(AnimateSpeed.fast);
  const [timings, setTimings] = useState([] as number[]);

  // To update the timestamp on a final selection
  useLayoutEffect(() => {
    if (ChooserState.done === chooserState) {
      setLastSelected(new Date())
    }
  }, [chooserState, selected]);

  useLayoutEffect(() => {
    if (ChooserState.chooseValue === chooserState) {
      if (0 === timings.length) {
        const timingArray: number[] = [];
        if (animate) {
          timingArray.push(...CHOICE_TIMING[animateSpeed]);
          setTimings(timingArray);
        } else {
          setChooserState(ChooserState.done);
        }

        const chooseNext = () => {
          const timing = timingArray.shift();
          const value = valueGen();
          setSelected(value);
          if (timing) {
            setTimeout(chooseNext, timing);
            setChooserState(ChooserState.waiting);
          } else {
            setChooserState(ChooserState.done);
          }
        };
        chooseNext();
      }
    }
  }, [chooserState, setTimings, animateSpeed, timings, animate, setChooserState, setSelected, valueGen]);

  if (null === selected) {
    return null;
  }

  return (
    <div>
      <div className={styles.selectionBar}>
        <span className={styles.selectedTime}>
          {ChooserState.done === chooserState &&
            lastSelected?.toLocaleString()
              // Add on milliseconds
              .replace(
                /(:\d\d)[^:]*?$/,
                '$1.' + ('00' + lastSelected.getTime() % 1000).substr(-3)
              )
          }
        </span>
        <span>
          {ChooserState.done === chooserState && 'Done'}
          {ChooserState.done !== chooserState && 'Choosing'}
        </span>
      </div>
      <div className={styleAs(
        styles.selection,
        styleIf(!animate || ChooserState.done === chooserState, styles.finalSelection)
      )}>
        {selected}
      </div>
    </div>
  );
};