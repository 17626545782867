import React from 'react';
import styles from './ActionBar.module.css';
import { Button, ButtonProps } from './basic/Button';

export type Action = {
  label: React.ReactNode;
  action: () => void;
} & {
  fn: (props: ButtonProps) => React.ReactNode;
}

export interface Actions {
  [k: string]: Action;
}

export interface ActionBarProps {
  onAll?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  actions: Actions;
  disabled?: boolean;
}

export const ActionBar: React.FC<ActionBarProps> = (props) => {
  const { disabled, onAll, actions } = props;

  const actionButtons = Object.keys(actions)
    .map(v => {
      const { label, action, fn } = actions[v];

      const extra: any = {};
      if (disabled) {
        extra.disabled = 'disabled';
      }

      if (fn) {
        return fn({
          key: { v },
          action: () => {
            onAll?.();
          },
          ...extra
        });
      }

      return (
        <Button
          {...extra}
          action={() => {
            onAll?.();
            action();
          }}
          key={v}
        >
          {label}
        </Button>
      );
    });

  return (
    <div className={styles.wrapper}>
      {actionButtons}
    </div>
  );
};
