import React from 'react';
import { Mark, MarkProps, genSymbol } from './Mark';
import { Link, LinkProps } from '../../navigation/Link';

export interface MarkLinkProps extends
  Omit<Omit<MarkProps, 'visualHover'>, 'onClick'>,
  Omit<LinkProps, 'to'> {
  linkTo: string;
}

export const MarkLink: React.FC<MarkLinkProps> = (props) => {
  const { linkTo, name, children = genSymbol(name), ...rest } = props;
  return (
    <Mark name={name} visualHover>
      <Link to={linkTo} {...rest} expand>
        {children}
      </Link>
    </Mark>
  );
};