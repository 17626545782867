import React, { useState, useContext, useMemo } from 'react';
import { Header, Breadcrumb } from './shared';

export interface ViewMeta {
  id: string;
  header: Header;
  priority: number;
  breadcrumb: Breadcrumb;
}

export interface ViewMetaFilter {
  (view: ViewMeta): boolean;
}

export interface ViewListenerState {
  views: ViewMeta[];
}

export interface FnMap {
  mountView: (view: ViewMeta) => void;
  unmountView: (view: ViewMeta) => void;
}

export interface ViewListenerFn {
  fn: FnMap;
}

interface ViewDataState {
  highest: ViewMeta[],
  lowest: ViewMeta[],
}
const ViewDataContext = React.createContext({} as ViewDataState);

const ViewListenerFnContext = React.createContext({} as ViewListenerFn);

export const useFn = () => {
  return useContext(ViewListenerFnContext).fn;
};

export const useViewData = () => {
  return useContext(ViewDataContext);
};

export interface ViewListenerProps {
}

export const ViewListener: React.FC<ViewListenerProps> = (props) => {
  const { children } = props;

  const [viewData, setViewData] = useState({
    views: [] as ViewMeta[],
    viewPtr: 0,
  });
  const refresh = () => setViewData(o => ({ ...o }));

  const viewDataState = useMemo(() => ({
    highest: viewData.views.slice().sort((a, b) => b.priority - a.priority),
    lowest: viewData.views.slice().sort((a, b) => a.priority - b.priority),
  } as ViewDataState), [viewData]);

  const [viewFunctions] = useState({
    fn: {
      mountView: (view: ViewMeta) => {
        viewData.views.push(view);
        refresh();
      },
      unmountView: (view: ViewMeta) => {
        const i = viewData.views.findIndex(v => view.id === v.id);
        viewData.views.splice(i, 1);
        refresh();
      },
    },
  });

  return (
    <ViewListenerFnContext.Provider value={viewFunctions}>
      <ViewDataContext.Provider value={viewDataState}>
        {children}
      </ViewDataContext.Provider>
    </ViewListenerFnContext.Provider>
  );
};