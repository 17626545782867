import React, { useState, useEffect, useMemo } from 'react';
import { Help } from '../../../../ui/Help';
import { asView } from '../../../../routing/View';
import { HelpSection } from '../../../../ui/HelpSection';
import { Link } from '../../../../navigation/Link';
import { Button } from '../../../../ui/basic/Button';
import { useStateExtPersisted } from '../../../../utility/store';
import { Mark } from '../../../../ui/basic/Mark';
import styles from './ColorChooser.module.css';
import { Grid } from '../../../../ui/Grid';
import { MarkRow } from '../../../../ui/basic/MarkRow';
import { Color, colorToHex, chooseColor, complement, muted, vibrant, brighter, darker } from './shared';
import { Section } from '../../../../ui/Section';
import { AdjustButtons } from './AdjustButtons';
import { ModalByButton } from '../../../../ui/ModalByButton';
import { SetFn, useStateExt } from '../../../../hooks/useStateExt';
import { Note } from '../../../../ui/basic/Note';
import { useManifest } from '../../../../hooks/useManifest';

interface ColorBlockProps {
  color: Color;
  setColor?: SetFn<Color>;
  label?: string;
  onChange?: () => void;
}

const ColorBlock: React.FC<ColorBlockProps> = (props) => {
  const { color, setColor, label, onChange } = props;

  const prefix = label ? label + ' ' : '';
  const colorHex = colorToHex(color);
  const onClick = setColor ? () => {
    setColor(color);
    onChange?.();
  } : undefined;
  return (
    <Mark onClick={onClick} name={prefix + colorHex}>
      <div className={styles.colorBlock} style={{ backgroundColor: colorHex }}></div>
    </Mark>
  );
};

export interface ColorChooserProps {
}

export const ColorChooser: React.FC<ColorChooserProps> = (props) => {
  const [, setHistoryOpen, historyOpenPair] = useStateExt(false);
  const [colorHistory, setColorHistory] = useState([] as Color[]);
  const [color, setColor, colorPair] = useStateExtPersisted('color', chooseColor())
  const [adjust, setAdjust] = useState(15);
  const compColor = complement(color);

  useEffect(() => {
    if (colorHistory[0] !== color) {
      setColorHistory(
        [color, ...colorHistory]
          .filter((v, i, arr) => i === arr.indexOf(v))
          .slice(0, 50)
      );
    }
  }, [colorHistory, color]);

  const colorHistoryShow = useMemo(() => {
    if (1 === colorHistory.length) {
      return (
        <p>No history yet, colors will be added here as they are chosen</p>
      );
    }

    return colorHistory
      .slice(1)
      .map((v) => <ColorBlock
        key={colorToHex(v)}
        color={v}
        setColor={setColor}
        onChange={() => setHistoryOpen(false)}
      />);
  }, [colorHistory, setColor, setHistoryOpen]);

  useManifest({
    override: {
      short_name: 'Color Chooser by Codaer',
    },
    genStartUrl: true,
  });

  return (
    <>
      <Help>
        <HelpSection label='What is this?'>
          Color Chooser is meant to help decide on a color. Although it is
          setup to help with websites (since it provides
          the <Link to='https://en.wikipedia.org/wiki/Web_colors' external>web color information</Link>)
          it can also be used to choose a color for almost any purpose.
        </HelpSection>

        <HelpSection label='How do I use this?'>
          <ul>
            <li>To choose a new color, click "choose color", one will be randomly selected</li>
            <li>
              Afterwards, the color may be adjusted using the adjustment buttons. To change the
              amount that the color changes, change the adjustment size
            </li>
            <li>To visit a previous color, open the color history</li>
            <li>
              To choose a color as the main color (when not already the main color), click on it
              and it will be selected
            </li>
          </ul>
        </HelpSection>

        <HelpSection label='How do I share colors?'>
          All adjustments to the color is saved in the URL. To share a color,
          just copy and paste the URL.
        </HelpSection>
      </Help>

      <Section>
        <Grid values={[
          <Button action={() => setColor(chooseColor)} wide>Random Color</Button>,
          <>
            <span>Adjust size:</span>
            <Button action={() => setAdjust(1)} disabled={1 === adjust}>1</Button>
            <Button action={() => setAdjust(5)} disabled={5 === adjust}>5</Button>
            <Button action={() => setAdjust(15)} disabled={15 === adjust}>15</Button>
          </>,
          <>
            <AdjustButtons colorPair={colorPair} colorId={'r'} style={styles.valueR} adjustSize={adjust} />
            <AdjustButtons colorPair={colorPair} colorId={'g'} style={styles.valueG} adjustSize={adjust} />
            <AdjustButtons colorPair={colorPair} colorId={'b'} style={styles.valueB} adjustSize={adjust} />
          </>,
          <ModalByButton label='Color History' openPair={historyOpenPair}>
            <Note>Color history is not saved in any way. Navigating away from this page will clear it.</Note>
            <MarkRow>
              {colorHistoryShow}
            </MarkRow>
          </ModalByButton>
        ]} />
      </Section>

      <Section border>
        <MarkRow>
          <ColorBlock color={color} />
        </MarkRow>
      </Section>

      <Section label='Adjusted' border>
        <MarkRow>
          <ColorBlock label='Muted' color={muted(color)} setColor={setColor} />
          <ColorBlock label='Vibrant' color={vibrant(color)} setColor={setColor} />
          <ColorBlock label='Brighter' color={brighter(color)} setColor={setColor} />
          <ColorBlock label='Darker' color={darker(color)} setColor={setColor} />
        </MarkRow>
      </Section>

      <Section label='Complement' border>
        <MarkRow>
          <ColorBlock label='Muted' color={muted(compColor)} setColor={setColor} />
          <ColorBlock label='Vibrant' color={vibrant(compColor)} setColor={setColor} />
          <ColorBlock label='Brighter' color={brighter(compColor)} setColor={setColor} />
          <ColorBlock label='Darker' color={darker(compColor)} setColor={setColor} />
        </MarkRow>
      </Section>
    </>
  );
};

export const ColorChooserView = asView(ColorChooser, {
  header: 'Color Chooser',
});