import React from 'react';
import { LinkConfig } from '../navigation/LinkConfig';
import { useFn } from './Router';

export interface NavigateLinkConfigProps {
}

export const NavigateLinkConfig: React.FC<NavigateLinkConfigProps> = (props) => {
  const fn = useFn();
  const { children } = props;
  return (
    <>
      <LinkConfig onClick={({ mouseEvent, data }) => {
        if (data.to) {
          if (!data.external) {
            mouseEvent.preventDefault();
            fn.goTo(data.to);
          }
        }
      }}>
        {children}
      </LinkConfig>
    </>
  );
};