import React from 'react';
import styles from './Alert.module.css';
import { styleAs } from '../../utility/style';

export enum AlertType {
  basic = 'basic',
  info = 'info',
  pass = 'pass',
  warn = 'warn',
  fail = 'fail',
}

export interface AlertProps {
  type?: AlertType;
}

export const Alert: React.FC<AlertProps> = (props) => {
  const { children, type = 'basic' } = props;
  return (
    <div className={styleAs(styles.alert, styles[type])}>
      {children}
    </div>
  );
};

interface TypedAlertProps extends Omit<AlertProps, 'type'> {
}

export const WarnAlert: React.FC<TypedAlertProps> = (props) => {
  return <Alert type={AlertType.warn} {...props} />
};

export const PassAlert: React.FC<TypedAlertProps> = (props) => {
  return <Alert type={AlertType.pass} {...props} />
};

export const FailAlert: React.FC<TypedAlertProps> = (props) => {
  return <Alert type={AlertType.fail} {...props} />
};

export const InfoAlert: React.FC<TypedAlertProps> = (props) => {
  return <Alert type={AlertType.info} {...props} />
};