import React, { useMemo, PropsWithChildren, useEffect } from 'react';
import { useLocation } from './Router';
import { useFn, ViewMeta } from './ViewListener';

const isViewProp = Symbol('ae-is-view-prop');
export const isView = (target: any) => {
  return target?.[isViewProp] || target?.type?.[isViewProp];
};

enum TokenTypes {
  wild = 'wild',
  literal = 'literal',
}

type WildToken = {
  type: TokenTypes.wild;
}

type LiteralToken = {
  type: TokenTypes.literal;
  value: string;
}

type Token = WildToken | LiteralToken;

const escapeRegexp = (value: string) => {
  return value.replace(escapeRegexp.pattern, '\\$&');
};
escapeRegexp.pattern = /[|.^$*+?)(}{\][-\\]/g;

const convertPath = (path: string) => {
  const tokens: Token[] = [];
  for (const part of path.replace(/\*/g, '\0*\0').split('\0')) {
    if ('*' === part) {
      if (TokenTypes.wild !== tokens[tokens.length - 1]?.type) {
        tokens.push({
          type: TokenTypes.wild,
        });
      }
    } else {
      tokens.push({
        type: TokenTypes.literal,
        value: part,
      });
    }
  }

  const pattern = [];
  for (const token of tokens) {
    if (TokenTypes.literal === token.type) {
      pattern.push(escapeRegexp(token.value));
    } else if (TokenTypes.wild === token.type) {
      pattern.push('.*?');
    }
  }
  pattern.push('/?');

  const regex = new RegExp('^' + pattern.join('') + '$', 'i');
  return {
    match: (value: string) => {
      if (!value.endsWith('/')) {
        value = value + '/';
      }
      return regex.test(value);
    },
  };
};

export interface ViewProps {
  header?: React.ReactNode;
  path?: string;
  viewId?: string;
  priority?: number;
}

export const View: React.FC<ViewProps> = (props) => {
  const { children, path, header, viewId: id, priority = 0 } = props;
  const location = useLocation();
  const fn = useFn();

  const matcher = useMemo(() => convertPath(path ?? ''), [path]);
  const matches = matcher.match(location.pathname);

  useEffect(() => {
    if (matches && id) {
      const meta = {
        id,
        header,
        priority,
      } as ViewMeta;

      if (header && path) {
        const label = header?.toString()?.toLowerCase();
        meta.breadcrumb = {
          label,
          location: path.replace(/\/\*$/, ''),
          viewId: id,
        };
      }

      fn.mountView(meta);
      return () => fn.unmountView(meta);
    }
  });

  if (!matches) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

export const asView = <T extends object>(Original: React.FC<T>, defProps: ViewProps = {}) => {
  const Result = (props: PropsWithChildren<T & ViewProps>) => {
    const { header, path, viewId, priority, ...rest } = props;
    const viewProps: ViewProps = { header, path, viewId, priority };
    const origProps = rest as T;
    return (
      <View {...viewProps}>
        <Original {...origProps} />
      </View>
    );
  };

  (Result as any)[isViewProp] = true;

  Result.defaultProps = defProps;

  return Result;
};