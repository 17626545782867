import React from 'react';
import styles from './Grid.module.css';
import { styleAs, styleIf } from '../utility/style';

export interface GridProps {
  values: React.ReactNode[];
  noGap?: boolean;
}

export const Grid: React.FC<GridProps> = (props) => {
  const { values, noGap } = props;

  const toShow = values.map((v, i) => {
    return <div key={i} className={styles.row}>{v}</div>
  });

  return (
    <div className={styleAs(
      styles.grid,
      styleIf(noGap, styles.noGap)
    )}>
      {toShow}
    </div>
  );
};
