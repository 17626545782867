import React from 'react';
import { Link as DefLink, LinkProps } from '../navigation/Link';
import styles from './Breadcrumbs.module.css';
import { useViewData, ViewMeta } from './ViewListener';

const STRIP_TRAILING = /\/$/;

const wildcardAndChild = (a: ViewMeta, b: ViewMeta) => {
  return a?.breadcrumb?.location?.replace(STRIP_TRAILING, '')
    === b?.breadcrumb?.location?.replace(STRIP_TRAILING, '');
};

export interface BreadcrumbsProps {
  Link?: React.FC<LinkProps>;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { Link = DefLink } = props;

  const viewMetas = useViewData().lowest;

  // The most current view that has a breadcrumb
  const [current] = viewMetas.filter(v => v.breadcrumb).slice(-1);

  // Skip the current breadcrumb and any wildcard parents that match it
  const skipIds = new Set(
    viewMetas
      .filter(v => v.id === current?.id || wildcardAndChild(v, current))
      .map(v => v.id)
  );

  // All the breadcrumbs
  const breadcrumbs = viewMetas.map(v => v.breadcrumb).filter(v => v);

  // Build the view
  const toShow = breadcrumbs.map((v) => {
    if (skipIds.has(v.viewId)) {
      return null
    }
    // Otherwise link to it
    return (
      <Link styleClasses={styles.crumb} key={v.location} to={v.location}>{v.label}</Link>
    );
  });

  return (
    <div className={styles.wrapper}>
      <Link styleClasses={styles.crumb} to='/'>home</Link>
      {toShow}
    </div>
  );
};