import React from 'react';
import styles from './Spacer.module.css';

export interface SpacerProps {
}

export const Spacer: React.FC<SpacerProps> = (props) => {
  const { children } = props;
  return (
    <>
      {children}
      <div className={styles.spacer}>
        &nbsp;
      </div>
    </>
  );
};