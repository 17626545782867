import React from 'react';
import styles from './PageHeader.module.css';

export interface PageHeaderProps {
}

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { children } = props;
  return (
    <p className={styles.pageHeader}>{children}</p>
  );
};