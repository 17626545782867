import React from 'react';
import { useLocation } from '../routing/Router';
import styles from './Navbar.module.css';
import { Breadcrumbs, BreadcrumbsProps } from '../routing/Breadcrumbs';

export interface NavbarProps extends BreadcrumbsProps {
}

export const Navbar: React.FC<NavbarProps> = (props) => {
  const { Link } = props;
  const location = useLocation();
  if ('/' !== location.pathname) {
    return (
      <div className={styles.navbar}>
        <Breadcrumbs Link={Link} />
      </div>
    );
  }
  return null;
};