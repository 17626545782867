import { useState } from "react";

export type SetFn<T> = React.Dispatch<React.SetStateAction<T>>;
export type SetPair<T> = [T, SetFn<T>];
export type StateAndPair<T> = [T, SetFn<T>, SetPair<T>];

export const useStateExt = <T>(
  initValue: T
): StateAndPair<T> => {
  const result: SetPair<T> = useState(initValue);
  return [result[0], result[1], result];
};