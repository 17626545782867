import React from 'react';
import graphic from './About.svg';
import { SvgSymbol } from '../../branding/SvgSymbol';
import { MarkLink } from '../../ui/basic/MarkLink';

const mark = <SvgSymbol src={graphic} alt='about icon' />;

export interface AboutMarkProps {
}

export const AboutMark: React.FC<AboutMarkProps> = (props) => {
  return (
    <MarkLink name='About' linkTo='/about'>
      {mark}
    </MarkLink>
  );
};