import { useStateExt, StateAndPair } from "../hooks/useStateExt";
import { useEffect } from "react";

export const ID = 'ae-v1--';

export const getData = (options: any = {}) => {
  const { load = () => window.location.hash } = options;
  let data: any = load().replace(/^#/, '');
  if (data.startsWith(ID)) {
    data = data.replace(ID, '');
    data = decodeURIComponent(data);
    data = JSON.parse(data);
  } else {
    data = null;
  }
  return data;
};

export const setData = (key: string, value: string | any, options: any = {}) => {
  const {
    save = (v: string) => window.history.replaceState(undefined, '', '#' + v),
    load = () => window.location.hash,
  } = options;

  let data = getData({ load }) || {};

  {
    // Build out the path to the value we want to store
    const keyPath = key.split('.');
    let node = data;
    for (const keyStep of keyPath.slice(0, -1)) {
      node = node[keyStep] = node[keyStep] || {};
    }
    node[keyPath[keyPath.length - 1]] = value;
  }

  data = JSON.stringify(data);
  data = encodeURIComponent(data);
  data = ID + data;
  save(data);
};

export const buildDataString = (data: { [k: string]: any }) => {
  const result = [''];
  const options = {
    save: (v: string) => result[0] = v,
    load: () => result[0],
  };
  for (const key of Object.keys(data)) {
    setData(key, data[key], options);
  }
  return result[0];
};

export const useStateExtPersisted = <T>(key: string, initIfMissing: T, options?: any): StateAndPair<T> => {
  const result = useStateExt((getData()?.[key] ?? initIfMissing) as T);
  const [toWatch] = result;

  useEffect(() => {
    setData(key, toWatch, options);
  }, [key, toWatch, options]);

  return result;
};