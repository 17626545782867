import React from 'react';
import { Row } from '../../layout/Row';

export interface MarkRowProps {
}

export const MarkRow: React.FC<MarkRowProps> = (props) => {
  const { children } = props;
  return (
    <Row spaceEven>
      {children}
    </Row>
  );
};