import React from 'react';
import styles from './Panel.module.css';
import { styleAs, Styleable } from '../utility/style';
import { Header } from '../ui/Header';

export interface PanelProps extends Styleable {
  tall?: boolean;
  wide?: boolean;
  header?: React.ReactNode;
}

export const Panel: React.FC<PanelProps> = (props) => {
  const { children, tall, wide, header } = props;
  const extra = [];
  if (tall) {
    extra.push(styles.tall);
  }
  if (wide) {
    extra.push(styles.wide);
  }
  return (
    <div className={styleAs(styles.panel, props, extra)}>
      {header && <Header>{header}</Header>}
      {children}
    </div>
  );
};