import React, { useEffect } from 'react';
import { Button } from '../basic/Button';
import { EmojiLightBulb, EmojiCrescentMoon } from '../basic/Emoji';
import styles from './ToggleLightDark.module.css';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { colorPref } from '../../local-storage-keys';
import { SettingsData } from '../../views/Settings/SettingsData';

enum ColorScheme {
  light = 'light',
  dark = 'dark',
}

const getDefaultColorStyle = () => {
  if (window?.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark').matches) {
      return ColorScheme.dark;
    }
    return ColorScheme.light;
  }
};

export interface ToggleLightDarkProps {
}

export const ToggleLightDark: React.FC<ToggleLightDarkProps> = (props) => {
  const [color, setColor] = useLocalStorage(colorPref.id, null);

  useEffect(() => {
    const classList = document?.body?.classList;
    classList?.toggle('def-color', null === color);
    classList?.toggle('light-color', ColorScheme.light === color);
    classList?.toggle('dark-color', ColorScheme.dark === color);
  }, [color]);

  const opposite = ColorScheme.light === (color ?? ColorScheme.light) ? ColorScheme.dark : ColorScheme.light;

  return (
    <Button className={styles.button} action={() => setColor(opposite)}>
      {ColorScheme.light === opposite ? <EmojiLightBulb /> : <EmojiCrescentMoon />}
    </Button>
  );
};

export const ToggleLightDarkSetting: React.FC<any> = (props) => {
  const [color, setColor] = useLocalStorage(colorPref.id, null);
  const defColor = getDefaultColorStyle();
  return (
    <SettingsData label='Setting: Color Preference' values={[
      { label: 'Current', value: color ? color : defColor },
      { label: 'Default', value: !color ? 'Using default' : 'Not using default' },
      color ? { label: 'Clear', value: <Button action={() => setColor(null)}>Clear Color</Button> } : null
    ]} help={colorPref.desc} />
  );
};