import React from 'react';
import { useQueryParams } from '../../routing/Router';
import { buildDataString } from '../../utility/store';
import { Link } from '../../navigation/Link';
import { Alert, AlertType } from '../../ui/alert/Alert';

export interface BackwardsCompatProps {
}

export const BackwardsCompat: React.FC<BackwardsCompatProps> = (props) => {
  const queryParams = useQueryParams();
  if (queryParams['choices']?.length) {
    const oldData: any = {
      choices: queryParams['choices'][0].split('\n'),
      hideHelp: true,
    };
    if (queryParams['animateChoosing']?.length) {
      oldData.animate = JSON.parse(queryParams['animateChoosing'][0]);
    }
    if (queryParams['autoDecide']?.length) {
      oldData.autoChoose = JSON.parse(queryParams['autoDecide'][0]);
    }
    const data = buildDataString(oldData);
    return (
      <Alert type={AlertType.warn}>
        It looks like you're using an old version of decision maker. Please
        {' '}<Link to={`/decision-maker/choose-from#${data}`}>visit the new version</Link>{' '}
        and update your bookmarks.
      </Alert>
    );
  }
  return null;
};