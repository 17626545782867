import React from 'react';
import { useStateExtPersisted } from '../../../../utility/store';
import { Button, ButtonProps } from '../../../../ui/basic/Button';
import { SetPair } from '../../../../hooks/useStateExt';

export interface PersistConfigButtonProps extends ButtonProps {
  persistKey: string;
  initValue: boolean;

  persistStatePair?: SetPair<boolean>;

  // Prefix for the label, doesn't change
  label: string;
  // Label value to use when on (added to prefix)
  labelOn?: string;
  // Label value to use when off (added to prefix)
  labelOff?: string;
}

export const PersistConfigButton: React.FC<PersistConfigButtonProps> = (props) => {
  const {
    action,
    persistKey,
    initValue,
    persistStatePair,
    label,
    labelOn = 'on',
    labelOff = 'off',
    ...rest
  } = props;

  const [value, setValue] = persistStatePair ?? useStateExtPersisted(persistKey, initValue);

  return (
    <>
      <Button
        action={(id) => {
          action?.(id);
          setValue((o: boolean) => !o);
        }}
        {...rest}
      >
        {label} {value ? labelOn : labelOff}
      </Button>
    </>
  );
};

type CustomButtonProps = Omit<Omit<Omit<PersistConfigButtonProps, 'persistKey'>, 'initValue'>, 'label'>;

export const AnimateConfigButton: React.FC<CustomButtonProps> = (props) => {
  return <PersistConfigButton
    {...props}
    persistKey='animate'
    initValue={true}
    label='Animation is'
  />
};