import React from 'react';
import { useViewData } from './ViewListener';
import { PageHeader } from '../ui/basic/PageHeader';

export interface AwareHeaderProps {
}

export const AwareHeader: React.FC<AwareHeaderProps> = (props) => {
  const [current] = useViewData().highest;
  const { header } = current ?? {};
  return (
    <>
      {header && <PageHeader>{header}</PageHeader>}
    </>
  );
};