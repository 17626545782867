import React from 'react';
import styles from './SvgSymbol.module.css';

export interface SvgSymbolProps {
  src: string;
  alt: string;
}

export const SvgSymbol: React.FC<SvgSymbolProps> = (props) => {
  const { src, alt } = props;
  return (
    <div className={styles.wrap}>
      <img src={src} alt={alt} />
    </div>
  );
};