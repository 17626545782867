import React from 'react';
import styles from './basic.module.css';

export interface NoteProps {
}

export const Note: React.FC<NoteProps> = (props) => {
  const { children } = props;
  return (
    <div className={styles.note}>
      <p>
        Note: {children}
      </p>
    </div>
  );
};