import React from 'react';
import styles from './basic.module.css';
import { styleAs, Styleable, styleIf } from '../../utility/style';
import { useId } from '../../hooks/useId';

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
export interface ButtonProps extends Styleable, Omit<HTMLButtonProps, 'action'> {
  action?: (id: string) => void;

  wide?: boolean;
  noCap?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { action, children, wide, noCap, ...rest } = props;
  const id = useId();

  return (
    <>
      <button
        {...rest}
        onClick={() => action?.(id)}
        className={styleAs(
          styles.button,
          props,
          styleIf(wide, styles.wide),
          styleIf(!noCap, styles.buttonCap)
        )}
      >
        {children}
      </button>
    </>
  );
};