import React from 'react';
import styles from './Mark.module.css';
import { styleAs, styleIf } from '../../utility/style';

export const MARK_STYLES = {
  symbolSvg: styles.symbolSvg,
};

/**
 * Converts a value into a shorter version of itself
 * by keeping only capitalized letters or those letters
 * that follow whitespace. The final result is entirely
 * converted to upper case.
 * @param value Value to convert into a symbol
 */
export const genSymbol = (value: string) => {
  return value
    .replace(/\s+([a-z])/g, (_, m) => m.toUpperCase())
    .replace(/[a-z]/g, '')
    .split(/\s/)
    .join('')
    .toUpperCase();
};

export interface MarkProps {
  name: string;
  symbol?: React.ReactNode;
  onClick?: () => void;
  visualHover?: boolean;
}

export const Mark: React.FC<MarkProps> = (props) => {
  const { name, children = genSymbol(name), onClick, visualHover = !!onClick } = props;
  return (
    <div className={styles.markWrap}>
      <div className={styleAs(
        styles.mark,
        styleIf(visualHover, styles.markHover)
      )} onClick={onClick}>
        {children}
      </div>
      <span className={styles.markName}>
        {name}
      </span>
    </div>
  );
};