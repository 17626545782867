import React from 'react';
import styles from './Link.module.css';
import { useLinkConfig } from './LinkConfig';
import { styleAs, styleIf } from '../utility/style';

interface LinkClickData {
  to?: string;
  external?: boolean;
}

export interface LinkClickEvent {
  mouseEvent: React.MouseEvent;
  data: LinkClickData;
}

export interface LinkClickEventHandler {
  (event: LinkClickEvent): void;
}

export interface LinkProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  to: string;
  onClick?: LinkClickEventHandler;
  styleClasses?: string | string[];
  external?: boolean;
  expand?: boolean;
}

export const Link: React.FC<LinkProps> = (props) => {
  const config = useLinkConfig();
  const { children, onClick = config?.onClick, styleClasses, external, expand } = props;
  const to = config?.publicPath?.(props.to);

  const styleClassesArr = Array.isArray(styleClasses) ? styleClasses : [styleClasses];
  const extras: any = {};
  if (external) {
    extras.target = '_blank';
    extras.rel = 'noopener noreferrer';
  }

  return (
    <a
      onClick={e => {
        onClick && onClick({
          mouseEvent: e,
          data: {
            external,
            to,
          },
        });
      }}
      className={styleAs(
        styles.main,
        styleIf(expand, styles.expand),
        ...styleClassesArr
      )}
      href={to}
      {...extras}
    >
      {children}
    </a>
  );
};

Link.defaultProps = {
  external: false,
};