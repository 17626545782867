import React, { useMemo } from 'react';
import styles from './SettingsData.module.css';
import { Section } from '../../ui/Section';
import { Help } from '../../ui/Help';

interface LabelValue {
  label: React.ReactNode;
  value: React.ReactNode;
}

export interface SettingsDataProps {
  label: string;
  help: React.ReactNode;
  values: (LabelValue | undefined | null)[];
}

export const SettingsData: React.FC<SettingsDataProps> = (props) => {
  const { values, label, help } = props;

  const toShow = useMemo(() => {
    return values
      .filter(v => v)
      .map((v, i) => {
        return (
          <React.Fragment key={i}>
            <div className={styles.label}>
              {v!.label}
            </div>
            <div className={styles.value}>
              {v!.value}
            </div>
          </React.Fragment>
        )
      });
  }, [values]);

  return (
    <Section label={label}>
      <Help>{help}</Help>
      <div className={styles.row}>
        {toShow}
      </div>
    </Section>
  );
};