import React, { useState, useContext } from 'react';
import { AnalysisContext } from './component-analyzer';

interface ComponentAnalysisState {
  analysisContext?: AnalysisContext;
}

const ComponentAnalysisContext = React.createContext({} as ComponentAnalysisState);

export const useAnalysis = () => {
  return useContext(ComponentAnalysisContext);
};

export interface ComponentAnalysisProps {
  analysisContext: AnalysisContext;
}

export const ComponentAnalysis: React.FC<ComponentAnalysisProps> = (props) => {
  const { analysisContext } = props;
  const [state] = useState({ analysisContext });
  return (
    <ComponentAnalysisContext.Provider value={state}>
      {props.children}
    </ComponentAnalysisContext.Provider>
  );
};