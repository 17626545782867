import React, { useContext, useState, useEffect } from 'react';
import { Header, Breadcrumb } from './shared';
import { parseQueryParams, QueryParamMap } from './query';
import { ViewListener } from './ViewListener';
import { analyzeAndRebuild } from './component-analyzer';
import { ComponentAnalysis } from './ComponentAnalysis';

interface FnMap {
  goTo: (loc: string) => Promise<void>;
  pushHeader: (value: Header) => void;
  popHeader: (value: Header) => void;
  pushBreadcrumb: (breadcrumb: Breadcrumb) => void;
}

interface RouterState {
  breadcrumbs: Breadcrumb[];
  headers: Header[];
  location: Location;
  queryParams: QueryParamMap;
  fn: FnMap;
}

const RouterContext = React.createContext({} as RouterState);

export const useLocation = () => {
  const { location } = useContext(RouterContext);
  return location;
};

export const useQueryParams = () => {
  const { queryParams } = useContext(RouterContext);
  return queryParams;
};

export const useFn = () => {
  const { fn } = useContext(RouterContext);
  return fn;
};

const loadLocation = (): Location => {
  const { location } = window;

  const pathname = location.pathname.replace((window as any)?.aePublicPath, '');

  return {
    ...location,
    pathname,
  };
};

export interface RouterProps {
}

export const Router: React.FC<RouterProps> = (props) => {
  const [routerState, setRouterState] = useState<RouterState>({
    location: loadLocation(),
    queryParams: parseQueryParams(loadLocation().search),
    headers: [],
    breadcrumbs: [],
    fn: {
      goTo: (loc: string) => {
        window.history.pushState({}, '', loc);
        const location = loadLocation();
        setRouterState({
          ...routerState,
          location: location,
          queryParams: parseQueryParams(location.search),
        });
      },
    },
  } as any);

  useEffect(() => {
    const cb = () => {
      const location = loadLocation();
      setRouterState({
        ...routerState,
        location: location,
        queryParams: parseQueryParams(location.search),
      });
    };
    window?.addEventListener('popstate', cb);
    return () => window?.removeEventListener('popstate', cb);
  });

  const analysis = analyzeAndRebuild(props);
  const { remapped } = analysis;

  return (
    <>
      <RouterContext.Provider value={routerState}>
        <ViewListener>
          <ComponentAnalysis analysisContext={analysis.ctx}>
            {remapped}
          </ComponentAnalysis>
        </ViewListener>
      </RouterContext.Provider>
    </>
  );
};