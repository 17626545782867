import React from 'react';
import { Panel } from '../layout/Panel';
import { Section } from '../ui/Section';
import { SimpleGuard } from '../ui/SimpleGuard';
import { Note } from '../ui/basic/Note';
import { asView } from '../routing/View';
import { Link } from '../navigation/Link';

const cookies = <Link to='https://simple.wikipedia.org/wiki/HTTP_cookie' external>cookies</Link>;
const browserStorage = <Link to='https://en.wikipedia.org/wiki/Web_storage' external>browser storage</Link>;

export interface PrivacyProps {
}

export const Privacy: React.FC<PrivacyProps> = (props) => {
  return (
    <>
      <Panel>
        <Section label='Summary'>
          <ul>
            <li>We don't track you.</li>
            <li>
              We sometimes use {cookies}/{browserStorage} to save your settings, but they
              aren't used for tracking. To view/clear any of these, visit
              the <Link to='/settings'>settings page</Link>.
            </li>
          </ul>
        </Section>

        <Section label='Long winded'>
          <p>
            Although we care about metrics, it is unreliable to trust tracking technology.
            In an age filled with privacy add-ons and ad blocking software, the data that
            gets collected is unreliable at best and can be dangerous to business at worst.
          </p>

          <p>
            Instead, we track ourselves and iterate on our failures as quickly as we can.
            With this, we are working with a complete perspective and can make the best
            decisions possible. Ultimately, it's what works for us.
          </p>

          <p>
            In order to give a less frustrating experience, some settings are saved
            via {cookies} and {browserStorage}, but these values aren't used for
            tracking purposes. To view/clear any of these, visit
            the <Link to='/settings'>settings page</Link>.
          </p>

          <p>
            That being said, we love hearing feedback. Feel free to reach out to us with
            feedback on our graphics, interface, wording, or anything else you can think
            of.
          </p>

          <Note>
            Any feedback submitted can not be construed as a request for
            collaboration and as such no requirements are stipulations are implied or
            explicitly stated.
          </Note>

          <SimpleGuard
            guardKey='E0EBB4BC-4549-4CDB-8C46-7D14C83BDC05'
            guardValue='q5WqpqSVpa5tl6SYmpKmcaexmg=='
            description='feedback email'
          />
        </Section>

        <Section label='More info'>
          <p>
            Want more info? Think we're lying? Just want to send us puppy pictures?
            Just send us an email and we'll get back to you.
          </p>
          <SimpleGuard
            guardKey='5D92D794-0655-47CB-A5A6-97CC16A1C6C9'
            guardValue='pbaiqKWasnSQn5qWmp9imrKv'
            description='privacy email'
          />
        </Section>
      </Panel >
    </>
  );
};

export const PrivacyView = asView(Privacy, {
  header: 'Privacy Policy',
});