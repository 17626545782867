import React from 'react';
import { MarkLink, MarkLinkProps } from '../../ui/basic/MarkLink';

export interface ExtMarkLinkProps extends Omit<MarkLinkProps, 'external'> {
}

export const ExtMarkLink: React.FC<ExtMarkLinkProps> = (props) => {
  return (
    <>
      <MarkLink external {...props} />
    </>
  );
};