import React from 'react';
import { Center } from '../../../ui/Center';
import { MarkRow } from '../../../ui/basic/MarkRow';
import { MarkLinkProps, MarkLink } from '../../../ui/basic/MarkLink';
import { EmojiUpsideDownFace } from '../../../ui/basic/Emoji';

const decidePath = '/decision-maker/choose-from';

const defaultSettingsChooseFrom = `%2C"animate"%3Atrue%2C"chooseOnLoad"%3Atrue%2C"hideHelp"%3Atrue`;

const quickDecisions: { [k: string]: MarkLinkProps } = {
  emoji: {
    name: 'Emoji',
    children: <EmojiUpsideDownFace />,
    linkTo: decidePath + `#ae-v1--{"candidates"%3A["😀"%2C"😁"%2C"😂"%2C"😃"%2C"😄"%2C"😅"%2C"😆"%2C"😇"%2C"😈"%2C"😉"%2C"😊"%2C"😋"%2C"😌"%2C"😍"%2C"😎"%2C"😏"%2C"😐"%2C"😑"%2C"😒"%2C"😓"%2C"😔"%2C"😕"%2C"😖"%2C"😗"%2C"😘"%2C"😙"%2C"😚"%2C"😛"%2C"😜"%2C"😝"%2C"😞"%2C"😟"%2C"😠"%2C"😡"%2C"😢"%2C"😣"%2C"😤"%2C"😥"%2C"😦"%2C"😧"%2C"😨"%2C"😩"%2C"😪"%2C"😫"%2C"😬"%2C"😭"%2C"😮"%2C"😯"%2C"😰"%2C"😱"%2C"😲"%2C"😳"%2C"😴"%2C"😵"%2C"😶"%2C"😷"]${defaultSettingsChooseFrom}}`,
  },
  yesNo: {
    name: 'Yes/No',
    linkTo: decidePath + `#ae-v1--{"candidates"%3A["yes"%2C"no"]${defaultSettingsChooseFrom}}`,
  },
  headsTails: {
    name: 'Coin Flip',
    linkTo: decidePath + `#ae-v1--{"candidates"%3A["heads"%2C"tails"]${defaultSettingsChooseFrom}}`,
  },
  youMe: {
    name: 'You/Me',
    linkTo: decidePath + `#ae-v1--{"candidates"%3A["you"%2C"me"]${defaultSettingsChooseFrom}}`,
  },
  day: {
    name: 'Day of the Week',
    children: 'Day',
    linkTo: decidePath + `#ae-v1--{"candidates"%3A["Sunday"%2C"Monday"%2C"Tuesday"%2C"Wednesday"%2C"Thursday"%2C"Friday"%2C"Saturday"]${defaultSettingsChooseFrom}}`,
  },
  abcd: {
    name: 'ABCD',
    children: 'A-D',
    linkTo: decidePath + `#ae-v1--{"candidates"%3A["a"%2C"b"%2C"c"%2C"d"]${defaultSettingsChooseFrom}}`,
  },
  aToZ: {
    name: 'Letter',
    children: 'A-Z',
    linkTo: decidePath + `#ae-v1--{"candidates"%3A["a"%2C"b"%2C"c"%2C"d"%2C"e"%2C"f"%2C"g"%2C"h"%2C"i"%2C"j"%2C"k"%2C"l"%2C"m"%2C"n"%2C"o"%2C"p"%2C"q"%2C"r"%2C"s"%2C"t"%2C"u"%2C"v"%2C"w"%2C"x"%2C"y"%2C"z"]${defaultSettingsChooseFrom}}`,
  },
};

const quickDecisionsShow = Object.values(quickDecisions).map(v => {
  return (
    <MarkLink key={v.name} {...v}>
      {v.children}
    </MarkLink>
  );
});

export interface QuickDecisionsProps {
}

export const QuickDecisions: React.FC<QuickDecisionsProps> = (props) => {
  return (
    <>
      <Center><p>Quick Decisions</p></Center>
      <MarkRow>
        {quickDecisionsShow}
      </MarkRow>
    </>
  );
};