import React from 'react';
import styles from './SectionLabel.module.css';

export interface SectionLabelProps {
}

export const SectionLabel: React.FC<SectionLabelProps> = (props) => {
  const { children } = props;
  return (
    <div className={styles.label}>
      {children}
    </div>
  );
};