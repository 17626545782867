import React from 'react';
import graphic from './DecisionMaker.svg';
import { SvgSymbol } from '../../branding/SvgSymbol';
import { MarkLink } from '../../ui/basic/MarkLink';

const mark = <SvgSymbol src={graphic} alt='decision maker icon' />;

export interface DecisionMakerMarkProps {
}

export const DecisionMakerMark: React.FC<DecisionMakerMarkProps> = (props) => {
  return (
    <MarkLink name='Decision Maker' linkTo='/decision-maker'>
      {mark}
    </MarkLink>
  );
};