import React from 'react';
import styles from './basic.module.css';

export interface EmojiProps {
  emoji: string;
  desc: string;
}

export const Emoji: React.FC<EmojiProps> = (props) => {
  const { emoji, desc } = props;
  return (
    <span className={styles.emoji} aria-label={desc}>{emoji}</span>
  );
};

const emojiComponent = (emoji: string, desc: string) => {
  return () => {
    return <Emoji emoji={emoji} desc={desc} />
  };
};

// As referenced https://unicode.org/emoji/charts/full-emoji-list.html
// Should use CLDR short name for desc
export const EmojiSeeNoEvil = emojiComponent('🙈', 'see-no-evil monkey');
export const EmojiUpsideDownFace = emojiComponent('🙃', 'upside-down face');
export const EmojiCrescentMoon = emojiComponent('🌙', 'crescent moon');
export const EmojiLightBulb = emojiComponent('💡', 'light bulb');