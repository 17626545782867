import React from 'react';
import styles from './App.module.css';
import { Column } from './layout/Column';
import { Router } from './routing/Router';
import { Navbar } from './navigation/Navbar';
import { NavigateLinkConfig } from './routing/LinkConfig';
import { AwareHeader } from './routing/AwareHeader';

import { HomeView } from './views/Home';
import { AboutView } from './views/About/About';
import { ContactView } from './views/Contact/Contact';
import { DecisionMakerView } from './views/DecisionMaker/DecisionMaker';
import { DecisionTypeView } from './views/DecisionMaker/decisions/DecisionType';
import { ChooseFromView } from './views/DecisionMaker/decisions/ChooseFrom/ChooseFrom';
import { NumberChooserView } from './views/DecisionMaker/decisions/NumberChooser/NumberChooser';
import { ProfilesView } from './views/Profiles';
import { Footer } from './views/Footer';
import { LicenseView } from './views/License';
import { AbeelxyView } from './views/affiliate/abeelxy/Abeelxy';
import { ColorChooserView } from './views/DecisionMaker/decisions/ColorChooser/ColorChooser';
import { PrivacyView } from './views/Privacy';
import { ToggleLightDark } from './ui/quick-action/ToggleLightDark';
import { SettingsView } from './views/Settings/Settings';

function App() {
  return (
    <div className={styles.app}>
      <ToggleLightDark />
      <Router>
        <NavigateLinkConfig>
          <Column className={styles.mainColumn}>
            <AwareHeader />
            <Navbar />
            <HomeView path='/' />
            <DecisionMakerView path='/decision-maker'>
              <DecisionTypeView path='/' />
              <ChooseFromView path='/choose-from' />
              <NumberChooserView path='/number-chooser' />
              <ColorChooserView path='/color-chooser' />
            </DecisionMakerView>
            <AboutView path='/about' />
            <ContactView path='/contact' />
            <ProfilesView path='/profiles' />
            <SettingsView path='/settings' />

            <PrivacyView path='/privacy' />
            <LicenseView path='/license' />
            <AbeelxyView path='/affiliate/abeelxy' />
          </Column>
          <Footer />
        </NavigateLinkConfig>
      </Router>
    </div>
  );
}

export default App;
