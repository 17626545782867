import React from 'react';
import { MarkLink } from '../../../../ui/basic/MarkLink';
import { SvgSymbol } from '../../../../branding/SvgSymbol';
import graphic from './NumberChooser.svg';

const mark = <SvgSymbol src={graphic} alt='number chooser mark' />;

export interface NumberChooserMarkProps {
}

export const NumberChooserMark: React.FC<NumberChooserMarkProps> = (props) => {
  return (
    <MarkLink name='Numbers' linkTo='/decision-maker/number-chooser'>
      {mark}
    </MarkLink>
  );
};