import { rand } from "./random";

const UUID_V4_BASE = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
export const uuid4 = () => {
  return UUID_V4_BASE.replace(/[xy]/g, (match) => {
    let result = 0;
    switch (match) {
      case 'x':
        result = rand(0xF);
        break;
      case 'y':
        result = rand(0x8, 0xB);
        break;
      default:
        throw new Error(`Invalid character in base: ${match}`);
    }
    return result.toString(16).toUpperCase();
  });
};