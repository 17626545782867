import React from 'react';
import { asView } from '../../../routing/View';
import { Link } from '../../../navigation/Link';
import { AffiliatePage } from '../AffiliatePage';
import logo from './Abeelxy.svg';
import styles from './Abeelxy.module.css';

export interface AbeelxyProps {
}

export const Abeelxy: React.FC<AbeelxyProps> = (props) => {
  return (
    <AffiliatePage
      styleCustom={styles}
      name='Abeelxy'
      logo={logo}
      descriptions={[
        <>
          Unfortunately, Abeelxy is currently not accepting new clients;
          luckily, Abeelxy has decided to recommend Codaer for all clients
          wishing to get started. We appreciate this and recommend those
          that are interested <Link to='/contact'>contact us</Link> to get started.
        </>
      ]}
      simpleGuardContact={{
        description: `Abeelxy's email`,
        guardKey: 'C2C2A8D8-C053-49DB-9902-5D39703BCCE0',
        guardValue: 'pJipm62hpaySg5GXmJKgsb1wkKim',
      }}
    />
  );
};

export const AbeelxyView = asView(Abeelxy, {
  header: 'Abeelxy',
});
