import React from 'react';
import { asView } from '../../../routing/View';
import { MarkRow } from '../../../ui/basic/MarkRow';
import { Center } from '../../../ui/Center';
import { QuickDecisions } from './QuickDecisions';
import { Help } from '../../../ui/Help';
import { HelpSection } from '../../../ui/HelpSection';
import { NumberChooserMark } from './NumberChooser/NumberChooserMark';
import { MarkLink } from '../../../ui/basic/MarkLink';
import { ColorChooserMark } from './ColorChooser/ColorChooserMark';

export interface DecisionTypeProps {
}

export const DecisionType: React.FC<DecisionTypeProps> = (props) => {
  return (
    <>
      <Help>
        <HelpSection label='What is Decision Maker?'>
          Decision Maker is meant to make decisions when it's too tough to do so.
          There are a few different types of Deciders that are available; each
          of them are well suited to one purpose. To learn more about them,
          click through to their page and read their help description.
        </HelpSection>

        <HelpSection label='How do I use it?'>
          Choose a Decider based on the type of decision to be made. Aren't sure
          which Decider to choose? Don't worry, we plan to make a Decider that
          will decide that too! Until then, click through on the different options
          or use our Quick Decisions section.
        </HelpSection>

        <HelpSection label='Quick Decisions?'>
          The Quick Decisions section contains a few common decisions that are
          likely to be used by everyone. This makes it easy for first time users
          to learn about Decision Maker and also makes it easy to get an answer
          when you don't want to setup your own decision.
        </HelpSection>

        <HelpSection label='Saving decisions'>
          Once you have a decision created, you can reuse that decision by saving
          the URL as a bookmark or copy+pasting the link somewhere safe. Once you
          load the page again, you're all ready to go.
        </HelpSection>
      </Help>

      <Center><p>Deciders</p></Center>
      <MarkRow>
        <MarkLink name='Choose From' linkTo='/decision-maker/choose-from' />
        <NumberChooserMark />
        <ColorChooserMark />
      </MarkRow>
      <QuickDecisions />
    </>
  );
};

export const DecisionTypeView = asView(DecisionType, {
  header: 'Decision Type',
});