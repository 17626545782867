import React from 'react';
import logo from './logo.svg';
import styles from './Logo.module.css';
import { Styleable, styleAs } from '../utility/style';
import { Column } from '../layout/Column';

export interface LogoProps extends Styleable {
}

export const Logo: React.FC<LogoProps> = (props) => {
  return (
    <>
      <Column>
        <img src={logo} className={styleAs(styles.logo, props)} alt="logo" />
        <h3 className={styles.header}>Codaer</h3>
      </Column>
    </>
  );
};