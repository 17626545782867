import React, { useState } from 'react';
import { Modal } from './Modal';
import { Button } from './basic/Button';
import { SetPair } from '../hooks/useStateExt';

export interface ModalByButtonProps {
  startOpen?: boolean;
  openPair?: SetPair<boolean>;
  label: string;
}

export const ModalByButton: React.FC<ModalByButtonProps> = (props) => {
  const { children, startOpen = false, label, openPair } = props;
  const [open, setOpen] = openPair ?? useState(startOpen);
  return (
    <>
      <Button action={() => setOpen(true)}>{label}</Button>
      <Modal open={open} onClose={() => setOpen(false)}>{children}</Modal>
    </>
  );
};