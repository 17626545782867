import React from 'react';

interface StorageKey {
  id: string;
  desc: React.ReactNode;
}

export const colorPref = {
  id: 'color-pref',
  desc: <>
    This is used to store whether or not the site should force
    a light/dark mode. The site tries to automatically detect
    what is wanted based on what the browser shares, but if
    a user wants to force a particular view, they can do so with this.
  </>,
};