export const genId = () => {
  return genId.base.replace(/[xy]/g, (match) => {
    let base, range;
    if ('x' === match) {
      base = 0;
      range = 0xF;
    } else {
      base = 8;
      range = 0x4;
    }
    return (base + Math.floor(Math.random() * range)).toString(16).toUpperCase();
  })
};
genId.base = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';