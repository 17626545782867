import React from 'react';
import { asView } from '../../routing/View';
import { Panel } from '../../layout/Panel';
import { Link } from '../../navigation/Link';
import { Section } from '../../ui/Section';

export interface AboutProps {
}

export const About: React.FC<AboutProps> = (props) => {
  return (
    <Panel>
      <Section label='At a glance'>
        <p>
          The core mission of Codaer is to eliminate the need for Codaer. It is only
          through automation, iterative design, and analysis that the problems of the
          world can become a thing of the past. It is our hope that there is eventually
          no need for us, but until that day we will be learning, teaching, and optimizing
          the systems of the world.
          </p>
      </Section>

      <Section label='Transparency'>
        <p>
          Honesty is the only way to get a full understanding of any given situation, and
          a full understanding allows for solutions that are reliable and adaptable. Since
          honesty is a core tenet of Codaer, we pride ourselves on being clear, concise, but
          also kind. Honesty doesn't need brutality.
          </p>
        <p>
          All things said, the truth can be a hard thing to deal with. At all steps of the
          process, Codaer will attempt to provide solutions to problems that are discovered
          by us, or to at least admit that we aren't well suited to solve the given problem.
          We would much rather help you find someone that is best suited than to provide a
          sub-optimal solution.
          </p>
      </Section>

      <Section label='Getting started'>
        <p>
          Sometimes it's not as easy as saying " Here's my problem, solve it please." If you
          aren't quite sure what problems you're running into or you just want a double check
          of someone else's solution, we're happy to discuss it all. When in doubt,
          it is best to <Link to='/contact'>contact us</Link> to find out more information.
          </p>
      </Section>
    </Panel>
  );
};

export const AboutView = asView(About, {
  header: 'About',
});