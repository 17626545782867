import React from 'react';
import { asView } from '../../routing/View';
import { Panel } from '../../layout/Panel';
import { Help } from '../../ui/Help';
import { HelpSection } from '../../ui/HelpSection';
import { ToggleLightDarkSetting } from '../../ui/quick-action/ToggleLightDark';

export interface SettingsProps {
}

export const Settings: React.FC<SettingsProps> = (props) => {
  return (
    <Panel>
      <Help>
        <HelpSection label='What is this?'>
          This page exposes all of the possible settings and
          preferences that have been saved or can be saved.
          It is meant to expose settings that are currently set
          and to allow for them to be changed / removed.
        </HelpSection>
      </Help>

      <ToggleLightDarkSetting />
    </Panel>
  );
};

export const SettingsView = asView(Settings, {
  header: 'Settings',
});