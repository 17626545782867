import React, { useState, useRef, useCallback } from 'react';
import styles from './TextInput.module.css';
import { useId } from '../hooks/useId';

interface OnButtonClickArgs {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

type HTMLInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
export interface TextInputProps extends HTMLInputProps {
  label?: string;
  buttonLabel?: string;
  onButtonClick?: (args: OnButtonClickArgs) => void;
  defaultValue?: string;
}

export const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    label: labelText,
    buttonLabel,
    onButtonClick,
    defaultValue = '',
    ...rest
  } = props;
  const id = useId();
  const inputRef = useRef(null as HTMLInputElement | null);

  const [value, setValue] = useState(defaultValue);

  const label = labelText
    ? <label className={styles.label} htmlFor={id}>{labelText}</label>
    : null;

  const submitAction = useCallback(() => {
    if (onButtonClick) {
      inputRef.current?.focus();
      onButtonClick({ value, setValue });
    }
  }, [value, onButtonClick]);

  const button = buttonLabel && onButtonClick
    ? <input
      className={styles.button}
      type='button'
      onClick={submitAction}
      value={buttonLabel}
    />
    : null;

  return (
    <>
      <span className={styles.wrapper}>
        {label}
        <input
          className={styles.input}
          ref={inputRef}
          type="text"
          id={id}
          onChange={e => setValue(e.target.value)}
          onKeyDown={e => {
            if ('enter' === e.key?.toLowerCase()) {
              submitAction();
            }
          }}
          value={value} {...rest}
        />
        {button}
      </span>
    </>
  );
};