import React from 'react';
import styles from './Footer.module.css';
import { Link } from '../navigation/Link';

export interface FooterProps {
}

export const Footer: React.FC<FooterProps> = (props) => {
  return (
    <div className={styles.footer}>
      &copy; Codaer unless otherwise noted. All rights reserved.
      Code is <Link to='/license'>ISC licensed</Link>.
    </div>
  );
};