import React from 'react';
import { Panel } from '../layout/Panel';
import { Logo } from '../branding/Logo';
import { asView } from '../routing/View';
import { DecisionMakerMark } from './DecisionMaker/DecisionMakerMark';
import { AboutMark } from './About/AboutMark';
import { ProfilesMark } from './Profiles/ProfilesMark';
import { Spacer } from '../ui/Spacer';
import { ContactMark } from './Contact/ContactMark';
import { MarkRow } from '../ui/basic/MarkRow';
import { MarkLink } from '../ui/basic/MarkLink';
import { EmojiSeeNoEvil } from '../ui/basic/Emoji';
import { SettingsMark } from './Settings/SettingsMark';

export interface HomeProps {
  linkOnClick?: (event: React.MouseEvent) => void;
}

export const Home: React.FC<HomeProps> = (props) => {
  return (
    <>
      <Panel>
        <Logo />
        <Spacer />
        <MarkRow>
          <DecisionMakerMark />
          <AboutMark />
          <ContactMark />
          <ProfilesMark />
          <MarkLink name='Privacy' linkTo='/privacy'><EmojiSeeNoEvil /></MarkLink>
          <SettingsMark />
        </MarkRow>
      </Panel>
    </>
  );
};

export const HomeView = asView(Home);