import queryString from 'querystring';

export type ParsedQuery = queryString.ParsedUrlQuery;

export interface QueryParamMap {
  [k: string]: string[];
}

export const parseQueryParams = (search: string): QueryParamMap => {
  const parsed = queryString.parse(search.replace(/^\?/, ''));
  const result: QueryParamMap = {};

  for (const key of Object.keys(parsed)) {
    const value = parsed[key];
    if (Array.isArray(value)) {
      result[key] = value;
    } else {
      result[key] = [value];
    }
  }

  return result;
};