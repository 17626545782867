import React from 'react';
import { Panel } from '../../layout/Panel';
import { SimpleGuardProps, SimpleGuard } from '../../ui/SimpleGuard';
import { styleAs } from '../../utility/style';
import styles from './AffiliatePage.module.css';

export interface AffiliatePageProps {
  name: React.ReactNode;
  logo?: string;
  descriptions: React.ReactNode[];
  simpleGuardContact?: SimpleGuardProps;

  styleCustom?: { readonly [k: string]: string; };
}

export const AffiliatePage: React.FC<AffiliatePageProps> = (props) => {
  const { name, logo, descriptions, simpleGuardContact, styleCustom } = props;
  return (
    <Panel>
      <div className={styleAs(styles.banner, styleCustom?.banner)}>
        {logo && <div className={styles.logoWrap}>
          <img className={styles.logo} src={logo} alt={`${name} logo`} />
        </div>}
        <div className={styleAs(styles.companyName, styleCustom?.companyName)}><span>{name}</span></div>
      </div>
      {descriptions.map((v, i) => <div key={i} className={styles.description}>{v}</div>)}

      {simpleGuardContact && <div className={styles.contactWrapper}>
        <span className={styles.contactNote}>{name} can be contacted at:</span>
        <SimpleGuard
          {...simpleGuardContact}
        />
      </div>}
      <p className={styles.disclosure}>
        This affiliate page is hosted by Codaer and the statements here have been approved by
        the affiliate listed.
      </p>
    </Panel>
  );
};