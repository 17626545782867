import { useStateExt, SetPair } from "./useStateExt";
import { useEffect, useState } from "react";

const EVENT_STORAGE_CHANGED = 'storage-changed';

const sendStorageEvent = (key: string) => {
  const event = new CustomEvent(EVENT_STORAGE_CHANGED, {
    detail: {
      key,
    }
  });
  window?.dispatchEvent(event);
};

const saveData = (key: string, value: string) => {
  window?.localStorage?.setItem?.(key, value);
  sendStorageEvent(key);
};

const loadData = (key: string) => {
  return window?.localStorage?.getItem?.(key);
};

const removeData = (key: string) => {
  window?.localStorage?.removeItem?.(key);
  sendStorageEvent(key);
};

export const useLocalStorage = (key: string, defaultValue: string | null): SetPair<string | null> => {
  const value = loadData(key) ?? defaultValue;
  const [result, setResult, pair] = useStateExt(value);
  const [lastResult, setLastResult] = useState(result);

  useEffect(() => {
    const handleEvent = (e: any) => {
      const { key: eventKey } = e.detail;
      if (eventKey === key) {
        const loadedValue = loadData(key);
        if (result !== loadedValue) {
          setResult(loadedValue);
        }
      }
    };
    window.addEventListener(EVENT_STORAGE_CHANGED, handleEvent);
    return () => window.removeEventListener(EVENT_STORAGE_CHANGED, handleEvent);
  });

  useEffect(() => {
    if (!Object.is(result, lastResult)) {
      setLastResult(result);
      if (null !== result) {
        saveData(key, result);
      } else {
        removeData(key);
      }
    }
  }, [result, lastResult, key]);

  return pair;
};
