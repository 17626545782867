import React from 'react';
import graphics from './Contact.svg';
import { SvgSymbol } from '../../branding/SvgSymbol';
import { MarkLink } from '../../ui/basic/MarkLink';

const mark = <SvgSymbol src={graphics} alt='contact icon' />;

export interface ContactMarkProps {
}

export const ContactMark: React.FC<ContactMarkProps> = (props) => {
  return (
    <MarkLink name='Contact' linkTo='/contact'>
      {mark}
    </MarkLink>
  );
};