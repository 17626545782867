import React from 'react';
import graphics from './Profiles.svg';
import { SvgSymbol } from '../../branding/SvgSymbol';
import { MarkLink } from '../../ui/basic/MarkLink';

const mark = <SvgSymbol src={graphics} alt='profiles icon' />;

export interface ProfilesMarkProps {
}

export const ProfilesMark: React.FC<ProfilesMarkProps> = (props) => {
  return (
    <MarkLink name='Profiles' linkTo='/profiles'>
      {mark}
    </MarkLink>
  );
};