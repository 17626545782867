import { useLayoutEffect } from "react";

const sourceManifest = {
  "short_name": "Codaer",
  "name": "Codaer site",
  "icons": [
    {
      "src": "favicon.ico",
      "sizes": "64x64 32x32 24x24 16x16",
      "type": "image/x-icon"
    },
    {
      "src": "logo-192.png",
      "type": "image/png",
      "sizes": "192x192"
    },
    {
      "src": "logo-512.png",
      "type": "image/png",
      "sizes": "512x512"
    }
  ],
  "start_url": ".",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff"
};

type Manifest = typeof sourceManifest;

type UseManifest = {
  override?: Partial<Pick<typeof sourceManifest, 'start_url' | 'short_name'>>;
  genStartUrl?: boolean;
};

export const useManifest = (options: UseManifest) => {
  const { override, genStartUrl } = options;

  const short_name = override?.short_name;
  const start_url = genStartUrl
    ? document.location.toString()
      .replace(document.location.protocol + '//' + document.location.host, '.')
    : override?.start_url;

  useLayoutEffect(() => {
    if (!start_url && !short_name) {
      return;
    }

    const result: Manifest = JSON.parse(JSON.stringify({
      ...sourceManifest,
      start_url,
      short_name,
    }));

    const blob = new Blob([
      JSON.stringify(result),
    ], { type: 'application/javascript' });
    const url = URL.createObjectURL(blob);

    const manifestLink = document.querySelector('head link[rel="manifest"]');
    const oldHref = manifestLink?.getAttribute('href');

    manifestLink?.setAttribute('href', url);

    return () => {
      URL.revokeObjectURL(url);
      if (oldHref) {
        manifestLink?.setAttribute('href', oldHref);
      }
    };
  }, [short_name, start_url]);
};