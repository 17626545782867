import React from 'react';
import { TextInput } from '../../../../ui/TextInput';
import { asView } from '../../../../routing/View';
import { Button } from '../../../../ui/basic/Button';
import { Chooser, ChooserState, useChooserState } from '../chooser/Chooser';
import { rand } from '../../../../utility/random';
import { useStateExtPersisted } from '../../../../utility/store';
import { useStateExt } from '../../../../hooks/useStateExt';
import { Spacer } from '../../../../ui/Spacer';
import { AnimateConfigButton } from '../chooser/PersistConfigButton';
import { Help } from '../../../../ui/Help';
import { HelpSection } from '../../../../ui/HelpSection';
import { useManifest } from '../../../../hooks/useManifest';

export interface NumberChooserProps {
  collapseHelp?: boolean;
}

export const NumberChooser: React.FC<NumberChooserProps> = () => {
  const [start, setStart] = useStateExtPersisted('start', 1);
  const [end, setEnd] = useStateExtPersisted('end', 100);

  const [, setChooserState, chooserStatePair] = useChooserState();
  const [, , selectedPair] = useStateExt(null as null | number);

  const [animate, , animatePair] = useStateExtPersisted('animate', true);

  useManifest({
    override: {
      short_name: 'Number Chooser by Codaer',
    },
    genStartUrl: true,
  });

  return (
    <>
      <Help>
        <HelpSection label='What is this?'>
          This Decider is meant to help make a decision when it comes to numbers.
          As of now, it only supports choosing numbers within a range, but it will
          eventually support much more.
        </HelpSection>

        <HelpSection label='How do I use it?'>
          Put the start and end of the range into the respective boxes and then click choose.
          For example, to get a number between one and ten, start would be 1 and end would be 10.
        </HelpSection>

        <HelpSection label='Customization'>
          <ul>
            <li>Animation: cycles through the options before choosing one</li>
          </ul>
        </HelpSection>

        <HelpSection label='Save for later'>
          Just like all of the other Deciders, this Decider can be saved for quick use
          by saving it as a bookmark. Once it is saved as a bookmark, just click on the
          bookmark, and the Decider will be loaded with your values.
        </HelpSection>
      </Help>

      <TextInput
        onBlur={({ target: { value } }) => setStart(parseInt(value))}
        label='Start'
        type='number'
        defaultValue={start?.toString()}
      />
      <TextInput
        onBlur={({ target: { value } }) => setEnd(parseInt(value))}
        label='End'
        type='number'
        defaultValue={end?.toString()}
      />
      <AnimateConfigButton persistStatePair={animatePair} />
      <Spacer />
      <Button action={() => setChooserState(ChooserState.chooseValue)} wide>Choose</Button>
      <Spacer />
      <Chooser
        animate={animate}
        chooserStatePair={chooserStatePair}
        selectedPair={selectedPair}

        valueGen={() => {
          const value = rand(start, end + 1);
          return value;
        }}
      />
    </>
  );
};

export const NumberChooserView = asView(NumberChooser, {
  header: 'Number Chooser',
});