import React from 'react';
import { asView } from '../../routing/View';
import { Panel } from '../../layout/Panel';
import { SimpleGuard } from '../../ui/SimpleGuard';

export interface ContactProps {
}

export const Contact: React.FC<ContactProps> = (props) => {
  return (
    <Panel>
      <p>
        In order to cut down on email farming and abuse, we've implemented
        a simple guard. In order to reveal our contact email, simply click
        the button below and it'll be revealed.
      </p>
      <SimpleGuard
        guardKey='005C0724-0602-4906-A395-D4D3275D1C60'
        guardValue='mJWhr593laORkZuiYJCjpg=='
        description='email'
      />
    </Panel>
  );
};

export const ContactView = asView(Contact, {
  header: 'Contact',
});